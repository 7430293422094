<template>
  <div class="report">
    <div class="page-header">
      <h1>{{ $t('Report') }} > {{$t('Target Product Report') }} </h1>
    </div>

    <el-select v-model="dateSelect">
      <el-option label="Daily" value="Daily"></el-option>
      <el-option label="Monthly" value="Monthly"></el-option>
      <el-option label="Yearly" value="Yearly"></el-option>
    </el-select>
    
    <el-select v-model="selectedProduct">
      <el-option v-for="product in products" :label="product.product_name_zh" :value="product.id"></el-option>
    </el-select>

    <div class="charts-cards">
      <div class="chart-inner">
        <h2>{{ $t('Total Sales') }}</h2>
        <TotalSales />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Product from '@/lib/product';
import Common from '@/lib/common';
import TotalSales from '@/components/dashboard/TotalSales.vue';

export default {
  name: 'Report',
  components: {
    TotalSales,
  },
  data(){
    return {
      'selectedProduct': '',
      'dateSelect': 'Daily',
      'products': [],
    };
  },
  mounted(){
    this.loadProducts();
  },
  methods: {
    async loadProducts(){
      try{
        const loginInfo = Common.getLoginInfo();
        const products = await Product.loadAllProducts(this.apiUrl, loginInfo);
        this.products = products;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.subheader{
  margin-top: 30px;
  margin-bottom: 30px;
}
.charts-cards{
  display: flex;
  flex-wrap: wrap;
}
.chart-inner{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin-right: 10px;
  margin-top: 50px;
  background-color: white;
  padding: 20px;
}
</style>